import UtilityHelper from '../utility-helper/utility-helper';
export default class FormNotificationElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.click = UtilityHelper.clickEvent;
    this._currentCss = null;
    setTimeout(() => {
      this._appendDom();

      this._setEvents();

      this._showOnLoad();
    }, 750);
  }

  disconnectedCallback() {
    this._removeEvents();
  }

  _getSemanticAttributes(type) {
    var css = 'semantic-';
    var icon = 'icon-content-';

    if (type === 'error') {
      css += 'error';
      icon += 'error';
    } else if (type === 'success') {
      css += 'success';
      icon += 'check';
    } else if (type === 'info') {
      css += 'info';
      icon += 'info';
    } else if (type === 'warning') {
      css += 'warning';
      icon += 'warning';
    } else {
      css += '';
      icon += '';
    }

    return {
      css: css,
      icon: icon
    };
  }

  _appendDom() {
    var dom = '<div class="form-message"  id="formMessage">';
    dom += '<div class="icon-content-close" id="formMessageClose"></div>';
    dom += '<div class="status" id="formMessageStatus">';
    dom += '<span id="formStatusIcon"></span>';
    dom += '</div>';
    dom += '<div class="status-description" id="formMessageDescription"></div>';
    dom += '<a id="formMessageLink"></a>';
    dom += '</div>';
    var $dom = $(dom);
    $(this).append($dom);
    this.formMessage = this.querySelector('#formMessage');
    this.formMessageClose = this.querySelector('#formMessageClose');
    this.formMessageStatus = this.querySelector('#formMessageStatus');
    this.formStatusIcon = this.querySelector('#formStatusIcon');
    this.formMessageDescription = this.querySelector('#formMessageDescription');
    this.formMessageLink = this.querySelector('#formMessageLink');
  }

  _setEvents() {
    var closeButton = this.formMessageClose;
    var click = this.click;
    closeButton.addEventListener(click, this._onClick.bind(this));
  }

  _removeEvents() {
    var closeButton = this.formMessageClose;
    var click = this.click;
    closeButton.removeEventListener(click, this._onClick.bind(this));
  }

  _onClick(event) {
    this.hide();
  }

  _showOnLoad() {
    var type = this.type;
    var message = this.message;

    if (type && message) {
      this.show(type, message, null, null);
    }
  }

  show(type, message, link, linkLabel) {
    var attr = this._getSemanticAttributes(type);

    if (this._currentCss) {
      this.formMessage.classList.remove(this._currentCss);
    }

    this.formMessage.classList.add(attr.css);
    this._currentCss = attr.css;
    this.formStatusIcon.setAttribute('class', '');
    this.formStatusIcon.classList.add(attr.icon);
    this.formMessageDescription.innerHTML = message;

    if (link) {
      this.formMessageLink.setAttribute('href', link);
      this.formMessageLink.innerHTML = linkLabel;
      this.formMessageLink.classList.remove('hide');
    } else {
      this.formMessageLink.classList.add('hide');
    }

    this.classList.add('visible');
  }

  hide() {
    this.classList.remove('visible');
    this.formMessageLink.classList.add('hide');
  }

  get type() {
    var type = this.getAttribute('type');

    if (type) {
      type = type.toLowerCase();
    }

    return type;
  }

  get message() {
    return this.getAttribute('message');
  }

}
customElements.define('form-notification', FormNotificationElement);